export default {
    CLEANUP: 'CLEANUP_EDIT_EXERCISES',
    EDIT_TASK: 'EDIT_TASK_EDIT_EXERCISES',
    DELETE_TASK: 'DELETE_TASK_EDIT_EXERCISES',
    ADD_NEW_TASK: 'ADD_NEW_TASK_EDIT_EXERCISES',
    ADD_ERROR: 'UPDATE_EXERCISES_ADD_ERROR',
    UPDATE_EXERCISES: 'UPDATE_EXERCISES_EDIT_EXERCISES',
    EDIT_EXERCISE: 'EDIT_EXERCISE_EDIT_EXERCISES',
    ADD_EXERCISE: 'ADD_EXERCISE_EDIT_EXERCISES',
    PRE_DELETE_EXERCISE: 'PRE_DELETE_EXERCISE_EDIT_EXERCISES',
    DELETE_EXERCISE: 'DELETE_EXERCISE_EDIT_EXERCISES',
    RESTORE_DELETED_EXERCISE: 'RESTORE_DELETED_EXERCISE_EDIT_EXERCISES',
    COPY_EXERCISES_FROM_OLD_DATA: 'COPY_EXERCISES_FROM_OLD_DATA_EDIT_EXERCISES'
}