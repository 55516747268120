export default {
	API_GET_USER_BY_ID: 'API_GET_USER_BY_ID_USER',
	API_UPDATE_USER: 'API_UPDATE_USER',
	SET_HIDDEN_FIELDS: 'SET_HIDDEN_FIELDS_UPDATE_USER',
	FILE_ERROR: 'FILE_ERROR_UPDATE_USER',
	CLEAR_ERROR: 'CLEAR_ERROR_UPDATE_USER',
	API_SEND_ACTIVATION: 'API_SEND_ACTIVATION_UPDATE_USER',
	API_DELETE_USER: 'API_DELETE_USER_UPDATE_USER',
	CLEANUP: 'CLEANUP_USER_ROUTER',
	EDIT_PHOTO: 'EDIT_PHOTO_UPDATE_USER',
	UPDATE_USER_DATA_LOCAL: 'UPDATE_USER_DATA_UPDATE_USER',
	FORBIDDEN_FIELD_ERROR: 'FORBIDDEN_FIELD_ERROR_UPDATE_USER'
}