export default {
	CLEANUP: 'CLEANUP_EDIT_COURSE',
	ADD_ERROR: 'ADD_ERROR_EDIT_COURSE',
	ADD_ENTRY: 'ADD_ENTRY_EDIT_COURSE',
	EDIT_ENTRY: 'EDIT_ENTRY_EDIT_COURSE',
	ADD_SECTION: 'ADD_SECTION_EDIT_COURSE',
	DELETE_ENTRY: 'DELETE_ENTRY_EDIT_COURSE',
	EDIT_SECTION: 'EDIT_SECTION_EDIT_COURSE',
	DELETE_SECTION: 'DELETE_SECTION_EDIT_COURSE',
	UPDATE_SECTIONS: 'EDIT_COURSE_UPDATE_SECTIONS',
	PRE_DELETE_ENTRY: 'PRE_DELETE_ENTRY_EDIT_COURSE',
	PRE_DELETE_SECTION: 'PRE_DELETE_SECTION_EDIT_COURSE',
	RESTORE_DELETED_ENTRY: 'RESTORE_DELETED_ENTRY_EDIT_COURSE',
	RESTORE_DELETED_SECTION: 'RESTORE_DELETED_SECTION_EDIT_COURSE',
	COPY_SECTIONS_FROM_OLD_DATA: 'COPY_OLD_COURSE_DATA_TO_EDIT_EDIT_CONTENT'
}